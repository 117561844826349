import firebase from "firebase/compat/app";
import { getUserPrivateSettings } from "lib/services/userSettings";
import isEqual from "lodash/isEqual";
import noop from "lodash/noop";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { auth } from "../lib/firebase";
import {
  logout as firebaseLogout,
  removeSessionCookies,
} from "../lib/firebaseAuthHelpers";
import { rollbar } from "../lib/rollbar";
import { UserSummary } from "../lib/services/users";
import { getUpdatedUserInfo, getUser } from "../lib/userRepository";
import { checkIsPrerender } from "./usePrerender";

export enum AuthState {
  PENDING,
  UNAUTHORIZED,
  AUTHORIZED,
}

interface Props {
  firebaseUser?: firebase.User | null;
  user?: UserSummary | null;
  authState: AuthState;
  logout: () => Promise<void>;
  isFetchingUserRole: boolean;
  isSuperUser: boolean;
  logoutInProgress: boolean;
}

const FirebaseAuthContext = React.createContext<Props>({
  authState: AuthState.PENDING,
  logout: () => new Promise(noop),
  isFetchingUserRole: false,
  isSuperUser: false,
  logoutInProgress: false,
});

export const FirebaseAuthProvider: React.FC = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>(AuthState.PENDING);
  const [firebaseUser, setFirebaseUser] = useState<firebase.User | null>();
  const [user, setUser] = useState<UserSummary | null>();
  const firebaseAuthMemo = useMemo(() => auth, []);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [isFetchingUserRole, setIsFetchingUserRole] = useState(false);
  const [logoutInProgress, setIsLogoutInProgress] = useState(false);

  useEffect(() => {
    if (!firebaseUser?.uid || checkIsPrerender()) {
      return;
    }

    const unsubscribeUserDetailsUpdate = getUpdatedUserInfo({
      userId: firebaseUser.uid,
      onSuccess: (updatedUser) => {
        setUser((prevInfo) => {
          if (isEqual(prevInfo, updatedUser)) {
            return prevInfo;
          }

          return updatedUser;
        });
      },
    });

    return () => {
      unsubscribeUserDetailsUpdate();
    };
  }, [firebaseUser]);

  useEffect(() => {
    firebaseAuthMemo.onAuthStateChanged(async (authUser) => {
      if (authUser != null) {
        setFirebaseUser(authUser);

        rollbar.configure({
          payload: {
            person: {
              id: authUser.uid,
            },
          },
        });
      } else if (authUser === null) {
        setAuthState(AuthState.UNAUTHORIZED);
      }
    });
  }, [firebaseAuthMemo]);

  useEffect(() => {
    if (authState === AuthState.AUTHORIZED) {
      getUserPrivateSettings()
        .then((privateSettings) => {
          const { is_editor, is_super_admin } =
            privateSettings?.insight_admin_permissions ?? {};
          setIsSuperUser(Boolean(is_editor || is_super_admin));
        })
        .finally(() => {
          setIsFetchingUserRole(false);
        });

      return;
    }

    setIsFetchingUserRole(false);
    setIsSuperUser(false);
  }, [authState]);

  useEffect(() => {
    async function fetchUser() {
      if (firebaseUser?.uid) {
        const fetchedUser = await getUser(firebaseUser.uid);
        setUser((prevInfo) => {
          if (isEqual(prevInfo, fetchedUser)) {
            return prevInfo;
          }

          return fetchedUser;
        });
        setIsFetchingUserRole(true);
        setAuthState(AuthState.AUTHORIZED);
      }
    }

    fetchUser();
  }, [firebaseUser]);

  async function logout() {
    setIsLogoutInProgress(true);

    try {
      await firebaseLogout();
      setFirebaseUser(null);
      setUser(null);
      removeSessionCookies();
      setAuthState(AuthState.UNAUTHORIZED);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLogoutInProgress(false);
    }
  }

  return (
    <FirebaseAuthContext.Provider
      value={{
        user,
        firebaseUser,
        authState,
        logout,
        isFetchingUserRole,
        isSuperUser,
        logoutInProgress,
      }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  );
};

export const useFirebaseAuth = () => {
  return useContext(FirebaseAuthContext);
};
